/* eslint-disable no-unused-vars */
import React, { useState, createContext } from 'react';

const initialState = {
  location: 'default',
  solution: 'default',
  businessHomeOpen: false,
  fastApprovalOpen: false,
  headerDisabled: false,
};

export const StateContext = createContext();

const StateProvider = ({ children }) => {
  const [state, setState] = useState(initialState);

  return (
    <StateContext.Provider value={{ state, setState }}>
      {children}
    </StateContext.Provider>
  );
};

const useSiteContext = () => {
  const context = React.useContext(StateContext);
  if (context === undefined) {
    throw new Error(`useSiteContext must be used within a SiteProvider`);
  }
  return context;
};

function useBusinessHomeLoans() {
  const { setState } = useSiteContext();
  function toggleShowBusiness(value) {
    setState((prevState) => {
      return {
        ...prevState,
        businessHomeOpen: value,
      };
    });
  }
  return toggleShowBusiness;
}

function useHeaderDisable() {
  const { setState } = useSiteContext();
  function toggleHeaderDisabled(value) {
    setState((prevState) => {
      return {
        ...prevState,
        headerDisabled: value,
      };
    });
  }
}

function useFastApproval() {
  const { setState } = useSiteContext();
  function toggleShowFastApproval(value) {
    setState((prevState) => {
      return {
        ...prevState,
        fastApprovalOpen: value,
      };
    });
  }
  return toggleShowFastApproval;
}

function useFilters() {
  const { setState } = useSiteContext();

  function updateLocation(value) {
    setState((prevState) => {
      return {
        ...prevState,
        location: value,
      };
    });
  }

  function updateSolution(value) {
    setState((prevState) => {
      return {
        ...prevState,
        solution: value,
      };
    });
  }

  function nukeFilters() {
    setState((prevState) => {
      return {
        ...prevState,
        location: 'default',
        solution: 'default',
      };
    });
  }

  return { updateLocation, updateSolution, nukeFilters };
}

export {
  StateProvider,
  useSiteContext,
  useFilters,
  useBusinessHomeLoans,
  useFastApproval,
  useHeaderDisable,
};
